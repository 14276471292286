import React, { useState, useEffect } from 'react';

// Garmin logo placeholder URL
const garminLogoUrl = 'https://in-parts.com/img/m/51.jpg'; // Replace with actual Garmin mini logo

const RecentActivities = ({ align = 'left' }) => {
  const [activityData, setActivityData] = useState(null);

  // Simulate fetching data from a file
  useEffect(() => {
    fetch('/data/garmin/af_celsius_2_28_2024.json')
      .then(response => response.json())
      .then(data => setActivityData(data))
      .catch(error => console.error('Error loading JSON:', error));
  }, []);

  if (!activityData) {
    return <div>Loading...</div>;
  }

  const { activityName, summaryDTO, locationName } = activityData;

  return (
    <div style={{ ...styles.activityContainer, textAlign: align }}>
      <h2>Recent Activity</h2>
      <div style={styles.activityHeader}>
        <img src={garminLogoUrl} alt="Garmin Logo" style={styles.garminLogo} />
        <h3 style={styles.activityName}>{activityName}</h3>
      </div>
      <p>{locationName}</p>
      <p>
        Distance: {(summaryDTO.distance / 1000).toFixed(2)} km | 
        Time: {(summaryDTO.duration / 60).toFixed(2)} mins | 
        Calories: {summaryDTO.calories} kcal
      </p>
    </div>
  );
};

const styles = {
  activityContainer: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    width: '250px', // Adjust width as needed
  },
  activityHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  garminLogo: {
    width: '30px', // Adjust logo size as needed
    marginRight: '10px',
  },
  activityName: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

export default RecentActivities;
