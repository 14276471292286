import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate
import { Icon } from '@blueprintjs/core';
import './NavBar.css';

const NavBar = ({ user, onLogout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const navigate = useNavigate(); // Hook to navigate programmatically

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setUploadOpen(false);
  };

  const toggleUploadDropdown = () => {
    setUploadOpen(!uploadOpen);
    setDropdownOpen(false);
  };

  const closeDropdowns = () => {
    setDropdownOpen(false);
    setUploadOpen(false);
  };

  const handleLogout = () => {
    onLogout(); // Trigger logout state management in App.js
    closeDropdowns();
    navigate('/'); // Redirect to login page
  };

  const handleUploadClick = () => {
    closeDropdowns();
    navigate('/upload/device'); // Navigate to upload page
  };
  const handleUploadManualClick = () => {
    closeDropdowns();
    navigate('/upload/manual'); // Navigate to upload page
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Logo</Link>
      </div>

      <div className="navbar-center">
        <ul className="navbar-links">
          <li>
            <Link to="/dashboard" onClick={closeDropdowns}>Dashboard</Link>
          </li>
          <li><a href="/#">Training</a></li>
          <li><a href="/#">Challenges</a></li>
          <li><a href="/#">Nutrition</a></li>
        </ul>
      </div>

      <div className="navbar-right">
        <ul className="navbar-links">
          <li><a href="https://astreas.co/pages/our-product" target='_blank'>Shop</a></li>
        </ul>
        <div className="navbar-profile" onClick={toggleDropdown}>
          <div className="navbar-avatar">
            <img src={user.profilePicture} alt={user.name} className="navbar-avatar-img" />
            <Icon icon="caret-down" className="navbar-caret" size={24} />
          </div>
        </div>

        {dropdownOpen && (
          <ul className="navbar-dropdown">
            <li>
              <Link to="/settings" onClick={closeDropdowns}>Settings</Link>
            </li>
            <li>
              <button onClick={handleLogout} className="logout-button">Log Out</button>
            </li>
          </ul>
        )}

        <div className="navbar-add" onClick={toggleUploadDropdown}>
          <Icon icon="add" className="navbar-add-img" size={24} />
        </div>

        {uploadOpen && (
          <ul className="navbar-upload">
            <li><Link to="/upload/device" onClick={handleUploadClick}>Upload activity</Link></li>
            <li><Link to="/upload/manual" onClick={handleUploadManualClick}>Add manual entry</Link></li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
