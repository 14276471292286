import React, { useState, useEffect } from 'react';

const TypeformQuestions = ({ onSubmit, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    fetch('/data/typeform/initial_questions.json')
      .then(response => response.json())
      .then(data => setQuestions(data.fields))
      .catch(error => console.error('Error loading JSON:', error));
  }, []);

  const handleInputChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(answers, questions);  // Pass the answers and questions array back to App.js
  };

  return (
    <div>
      <h2>Typeform Questions</h2>
      <form onSubmit={handleSubmit}>
        {questions.length > 0 ? (
          questions.map((question) => (
            <div key={question.id}>
              <label>{question.title}</label>
              <input
                type="text"
                value={answers[question.id] || ''}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
              />
            </div>
          ))
        ) : (
          <p>Loading questions...</p>
        )}
        <button type="submit">Submit</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

export default TypeformQuestions;
