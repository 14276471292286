import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button onClick={onClose} style={styles.closeButton}>X</button>
        <div style={styles.modalContent}>
          {children} {/* Render form or other content passed to the modal */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '60%',  // Adjust the width as needed
    maxHeight: '80%',  // Ensure the modal doesn't exceed 80% of the viewport height
    position: 'relative',
    overflow: 'auto',  // Ensure that the modal content scrolls if it exceeds the modal size
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
  modalContent: {
    marginTop: '40px', // To avoid overlap with close button
  },
};

export default Modal;
