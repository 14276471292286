// import React, { useState } from 'react';

// const Upload = () => {
//   const [activeTab, setActiveTab] = useState('device');

//   const renderContent = () => {
//     switch (activeTab) {
//       case 'device':
//         return <DeviceContent />;
//       case 'file':
//         return <FileContent />;
//       case 'manual':
//         return <ManualContent />;
//       default:
//         return <DeviceContent />;
//     }
//   };

//   return (
//     <div className="upload-container" style={styles.uploadContainer}>
//       <div className="side-nav" style={styles.sideNav}>
//         <ul>
//           <li className={activeTab === 'device' ? 'active' : ''} onClick={() => setActiveTab('device')}>Device</li>
//           <li className={activeTab === 'file' ? 'active' : ''} onClick={() => setActiveTab('file')}>File</li>
//           <li className={activeTab === 'manual' ? 'active' : ''} onClick={() => setActiveTab('manual')}>Manual</li>
//         </ul>
//       </div>
//       <div className="content" style={styles.content}>
//         {renderContent()}
//       </div>
//     </div>
//   );
// };

// const DeviceContent = () => (
//   <div style={styles.contentSection}>
//     <h2>Upload and Sync Your Activities</h2>
//     {/* Placeholder for device content */}
//     <p>Device content placeholder</p>
//   </div>
// );

// const FileContent = () => (
//   <div style={styles.contentSection}>
//     <h2>Upload Your Activities</h2>
//     {/* Placeholder for file content */}
//     <p>File content placeholder</p>
//   </div>
// );

// const ManualContent = () => (
//   <div style={styles.contentSection}>
//     <h2>Manual Entry</h2>
//     {/* Placeholder for manual content */}
//     <p>Manual content placeholder</p>
//   </div>
// );

// const styles = {
//   uploadContainer: {
//     display: 'flex',
//   },
//   sideNav: {
//     width: '200px',
//     padding: '10px',
//     borderRight: '1px solid #ccc',
//   },
//   content: {
//     padding: '20px',
//     width: '100%',
//   },
//   contentSection: {
//     textAlign: 'center',
//   },
// };

// export default Upload;

// ---

// import React, { useState } from 'react';
// import { Link, Route, Routes, useNavigate } from 'react-router-dom'; // Import navigation tools
// import './Upload.css'; // Assuming you have separate CSS

// // Components for different sections
// import Device from './Device'; // Device upload component
// import File from './File'; // File upload component
// import Manual from './Manual'; // Manual entry component

// const Upload = () => {
//   const navigate = useNavigate();
//   const [activeSection, setActiveSection] = useState('device');

//   // Function to handle section change
//   const handleSectionChange = (section) => {
//     setActiveSection(section);
//     navigate(`/upload/${section}`);
//   };

//   return (
//     <div className="upload-container">
//       <div className="side-nav">
//         <ul>
//           <li
//             className={activeSection === 'device' ? 'active' : ''}
//             onClick={() => handleSectionChange('device')}
//           >
//             Device
//           </li>
//           <li
//             className={activeSection === 'file' ? 'active' : ''}
//             onClick={() => handleSectionChange('file')}
//           >
//             File
//           </li>
//           <li
//             className={activeSection === 'manual' ? 'active' : ''}
//             onClick={() => handleSectionChange('manual')}
//           >
//             Manual
//           </li>
//         </ul>
//       </div>
//       <div className="upload-content">
//         <Routes>
//           <Route path="/device" element={<Device />} />
//           <Route path="/file" element={<File />} />
//           <Route path="/manual" element={<Manual />} />
//         </Routes>
//       </div>
//     </div>
//   );
// };

// export default Upload;

// --

import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom'; // Import necessary tools from react-router-dom
import Device from './Device';
import File from './File';
import Manual from './Manual';
import './Upload.css'; // Assuming you have separate CSS for Upload

const Upload = () => {
  const location = useLocation(); // To determine active link

  return (
    <div className="upload-container">
      <div className="side-nav">
        <ul>
          <li className={location.pathname === '/upload/device' ? 'active' : ''}>
            <Link to="/upload/device">Device</Link>
          </li>
          <li className={location.pathname === '/upload/file' ? 'active' : ''}>
            <Link to="/upload/file">File</Link>
          </li>
          <li className={location.pathname === '/upload/manual' ? 'active' : ''}>
            <Link to="/upload/manual">Manual</Link>
          </li>
        </ul>
      </div>
      <div className="upload-content">
        <Routes>
          <Route path="device" element={<Device />} />
          <Route path="file" element={<File />} />
          <Route path="manual" element={<Manual />} />
        </Routes>
      </div>
    </div>
  );
};

export default Upload;
