import React, { useState } from 'react';

const Manual = () => {
  const [formData, setFormData] = useState({
    distance: '',
    duration: '',
    elevation: '',
    sport: 'Ride',
    date: '',
    time: '',
    description: '',
    typeOfRide: '',
    tags: [],
    perceivedExertion: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to submit form data
  };

  return (
    <div className="manual-section">
      <h2>Add Manual Entry</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Distance
          <input
            type="text"
            name="distance"
            value={formData.distance}
            onChange={handleChange}
          />
        </label>
        <label>
          Duration
          <input
            type="text"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
          />
        </label>
        <label>
          Elevation
          <input
            type="text"
            name="elevation"
            value={formData.elevation}
            onChange={handleChange}
          />
        </label>
        <label>
          Sport
          <select name="sport" value={formData.sport} onChange={handleChange}>
            <option value="Ride">Ride</option>
            <option value="Run">Run</option>
            {/* Add more options */}
          </select>
        </label>
        {/* Add more fields as needed */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Manual;
