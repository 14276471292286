import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const GoalTracking = () => {
  // Mocked goals data for now
  const goals = {
    steps: {
      goal: 10000,
      current: 9152,
      color: '#007bff',
    },
    distance: {
      goal: 50, // km for the week
      current: (9985.29 / 1000).toFixed(2),
      color: '#28a745',
    },
    sleep: {
      goal: 8, // hours per night
      current: 6.5,
      color: '#ffc107',
    },
    calories: {
      goal: 2000,
      current: 787,
      color: '#dc3545',
    },
  };

  return (
    <div style={styles.goalTrackingContainer}>
      <h2>Health-Related Goals</h2>
      <div style={styles.chartContainer}>
        {/* Stack each PieChart with progressively smaller size */}
        <div style={styles.ringContainer}>
          {/* Steps Ring - Outer */}
          <PieChart
            data={[{ value: (goals.steps.current / goals.steps.goal) * 100, color: goals.steps.color }]}
            lineWidth={15} // Reduced line width to compress rings
            startAngle={-90}
            rounded
            totalValue={100}
            style={styles.outerRing}
          />
          {/* Distance Ring - Second */}
          <PieChart
            data={[{ value: (goals.distance.current / goals.distance.goal) * 100, color: goals.distance.color }]}
            lineWidth={15} // Reduced line width
            startAngle={-90}
            rounded
            totalValue={100}
            style={styles.middleRing}
          />
          {/* Sleep Ring - Third */}
          <PieChart
            data={[{ value: (goals.sleep.current / goals.sleep.goal) * 100, color: goals.sleep.color }]}
            lineWidth={15} // Reduced line width
            startAngle={-90}
            rounded
            totalValue={100}
            style={styles.innerRing}
          />
          {/* Calories Ring - Center */}
          <PieChart
            data={[{ value: (goals.calories.current / goals.calories.goal) * 100, color: goals.calories.color }]}
            lineWidth={15} // Reduced line width
            startAngle={-90}
            rounded
            totalValue={100}
            style={styles.centerRing}
          />
        </div>
      </div>

      <div style={styles.legend}>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: goals.steps.color }}></span>
          <span>Steps: {(goals.steps.current / goals.steps.goal * 100).toFixed(2)}%</span>
        </div>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: goals.distance.color }}></span>
          <span>Distance: {(goals.distance.current / goals.distance.goal * 100).toFixed(2)}%</span>
        </div>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: goals.sleep.color }}></span>
          <span>Sleep: {(goals.sleep.current / goals.sleep.goal * 100).toFixed(2)}%</span>
        </div>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: goals.calories.color }}></span>
          <span>Calories: {(goals.calories.current / goals.calories.goal * 100).toFixed(2)}%</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  goalTrackingContainer: {
    padding: '0px', // Reduced padding
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '0px',
    textAlign: 'center',
    maxWidth: '350px', // Compress the width of the container
    margin: 'auto',
  },
  chartContainer: {
    position: 'relative',
    width: '200px', // Reduced width
    height: '200px', // Reduced height
    margin: '0 auto',
  },
  ringContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  outerRing: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  middleRing: {
    position: 'absolute',
    top: '15px',
    left: '15px',
    width: '170px',
    height: '170px',
  },
  innerRing: {
    position: 'absolute',
    top: '30px',
    left: '30px',
    width: '140px',
    height: '140px',
  },
  centerRing: {
    position: 'absolute',
    top: '45px',
    left: '45px',
    width: '110px',
    height: '110px',
  },
  legend: {
    marginTop: '15px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  legendColor: {
    width: '12px',
    height: '12px',
    display: 'inline-block',
    marginRight: '8px',
  },
};

export default GoalTracking;
