import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure global styles are imported

const Header = ({ user, totalActivities, activeDays }) => {
  const [hoveredDay, setHoveredDay] = useState(null);
  const [calendarDays, setCalendarDays] = useState([]);

  // Calculate the last 4 weeks from today's date and align the calendar properly
  useEffect(() => {
    const calculateLastFourWeeks = () => {
      const today = new Date();
      let currentDayOfWeek = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
      if (currentDayOfWeek === 0) currentDayOfWeek = 7; // Treat Sunday as 7 to align properly with Monday as 1

      const daysInLastFourWeeks = [];

      // Add empty spots to push the current day to the last row, correct column
      const startOffset = 7 - currentDayOfWeek; // How many empty spots after today's column
      for (let i = 0; i < startOffset; i++) {
        daysInLastFourWeeks.unshift(null); // Insert null to push current day to the correct place
      }

      // Populate the last 28 days (4 weeks) before today
      for (let i = 0; i < 28; i++) {
        const day = new Date(today);
        day.setDate(today.getDate() - i);
        daysInLastFourWeeks.unshift(day); // Push the days in reverse order to start from the oldest
      }

      setCalendarDays(daysInLastFourWeeks.slice(-28)); // Only keep the last 4 weeks (28 days)
    };

    calculateLastFourWeeks();
  }, []);

  const handleMouseEnter = (dayIndex) => {
    setHoveredDay(dayIndex);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  const activityColors = {
    Run: '#007BFF',
    Workout: '#4CAF50',
    Cycle: '#FF5722',
    Swim: '#03A9F4',
    Anaerobic: '#FFC107'
  };

  return (
    <div style={styles.headerContainer}>
      {/* Profile Header */}
      <div style={styles.header}>
        <div style={styles.profileSection}>
          <img
            src={user.profilePicture}
            alt="Profile"
            style={styles.profilePicture}
          />
          <div style={styles.userInfo}>
            <h2 style={styles.userName}>{user.name}</h2>
            <p style={styles.profileType}>{user.profileType}</p>
          </div>
        </div>

        <div style={styles.statsSection}>
          <div style={styles.statItem}>
            <h4 style={styles.statNumber}>{user.totalDistance} mi</h4>
            <p style={styles.statLabel}>Total Distance</p>
          </div>
          <div style={styles.statItem}>
            <h4 style={styles.statNumber}>{user.totalTime} hrs</h4>
            <p style={styles.statLabel}>Total Time</p>
          </div>
          <div style={styles.statItem}>
            <h4 style={styles.statNumber}>{user.totalCalories} kcal</h4>
            <p style={styles.statLabel}>Total Calories</p>
          </div>
        </div>
      </div>

      <div style={styles.bottomSection}>
        <div style={styles.last4Weeks}>
          <h4>Last 4 Weeks</h4>
          <p className="text-display5">{totalActivities}</p>
          <br />
          <p>Total Activities</p>
        </div>

        {/* Calendar Section */}
        <div style={styles.calendar}>
          <div style={styles.legendContainer}>
            <div /* style={{ color: 'blue' }} */ style={styles.dayLabel}>Legend</div>
            <div style={styles.legendItem}>
              <span style={{ ...styles.legendDot, backgroundColor: '#007BFF' }}></span>
              <span>Run</span>
            </div>
            <div style={styles.legendItem}>
              <span style={{ ...styles.legendDot, backgroundColor: 'green' }}></span>
              <span>Workout</span>
            </div>
            <div style={styles.legendItem}>
              <span style={{ ...styles.legendDot, backgroundColor: '#FF5722' }}></span>
              <span>Cycle</span>
            </div>
            <div style={styles.legendItem}>
              <span style={{ ...styles.legendDot, backgroundColor: 'aqua' }}></span>
              <span>Swim</span>
            </div>
            <div style={styles.legendItem}>
              <span style={{ ...styles.legendDot, backgroundColor: 'orange' }}></span>
              <span>Anaerobic</span>
            </div>
          </div>
          <div style={styles.calendarContainer}>
            {/* Days of the week header */}
            <div style={styles.daysOfWeek}>
              {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
                <div key={index} style={styles.dayLabel}>{day}</div>
              ))}
            </div>

            {/* Calendar grid with the last 4 weeks */}
            <div style={styles.calendarGrid}>
              {calendarDays.map((day, index) => {
                if (!day) {
                  return <div key={index} style={styles.emptyDay}></div>;
                }
                const dayNumber = day.getDate();
                const isActive = activeDays.includes(dayNumber);
                const isHovered = hoveredDay === index;

                return (
                  <div
                    key={index}
                    style={styles.calendarDay}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      style={{
                        ...styles.calendarDot,
                        ...(isHovered ? styles.hoveredDayStyle : {}),
                        ...(isActive ? styles.activeDot : {}),
                      }}
                    >
                      {isHovered ? <b>{dayNumber}</b> : ''}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* Rececont Recommendations */}
        <div style={styles.last4Weeks2}>
          <h4>Last 4 Weeks</h4>
          <p className="text-display5">{totalActivities}</p>
          <br />
          <p>Total Activities</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e6e6e6',
    paddingBottom: '20px',
    marginBottom: '20px',
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePicture: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    marginRight: '20px',
    marginLeft: '40px',
    border: '2px solid #e6e6e6',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  userName: {
    fontSize: '1.8rem',
    color: '#343a40',
    marginBottom: '5px',
  },
  profileType: {
    fontSize: '1rem',
    color: '#868e96',
  },
  statsSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statItem: {
    marginLeft: '30px',
    marginRight: '30px',
    textAlign: 'center',
  },
  statNumber: {
    fontSize: '1.5rem',
    color: '#007bff',
    marginBottom: '5px',
  },
  statLabel: {
    fontSize: '1rem',
    color: '#6c757d',
  },
  bottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  last4Weeks: {
    flex: 1,
    marginRight: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '2px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  /* test */
  legendContainer: {
    textAlign: 'left',
    marginLeft: '20px',
    flex: 0.2,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  legendDot: {
    display: 'inline-block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginRight: '10px',
  },
    /* test */
  calendar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '2px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  daysOfWeek: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 40px)',
    textAlign: 'center',
    marginBottom: '10px',
  },
  dayLabel: {
    fontWeight: 'bold',
    color: '#333',
  },
  calendarGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 25px)',
    gridGap: '15px',
    marginTop: '5px',
  },
  calendarDay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarDot: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: '#ccc',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
  },
  activeDot: {
    backgroundColor: '#007bff',
  },
  hoveredDayStyle: {
    width: '15px',
    height: '15px',
    fontSize: '14px',
    backgroundColor: 'white', // Change the dot to white on hover
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyDay: {
    width: '15px',
    height: '15px',
  },
  last4Weeks2: {
    flex: 1,
    marginRight: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '2px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
};

export default Header;
