import React from 'react';

const WearablesSummary = ({ activityData }) => {
  if (!activityData) {
    return <div>Loading...</div>;
  }

  const { summaryDTO } = activityData;

  return (
    <div style={styles.wearablesContainer}>
      <div style={styles.wearableCard}>
        <h4>Avg Heart Rate</h4>
        <p>{summaryDTO.averageHR} bpm</p>
      </div>
      <div style={styles.wearableCard}>
        <h4>Max Heart Rate</h4>
        <p>{summaryDTO.maxHR} bpm</p>
      </div>
      <div style={styles.wearableCard}>
        <h4>Run Cadence</h4>
        <p>{summaryDTO.averageRunCadence} spm</p>
      </div>
      <div style={styles.wearableCard}>
        <h4>Avg Temperature</h4>
        <p>{summaryDTO.averageTemperature.toFixed(1)} °C</p>
      </div>
      <div style={styles.wearableCard}>
        <h4>Stride Length</h4>
        <p>{summaryDTO.strideLength.toFixed(2)} m</p>
      </div>
    </div>
  );
};

const styles = {
  wearablesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  wearableCard: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    width: '100px',
  },
};

export default WearablesSummary;
