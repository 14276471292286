import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileSettings = ({ user, userAnswers, onSave }) => {
  const [name, setName] = useState(user.name);
  const [profileType, setProfileType] = useState(user.profileType);
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  const handleSave = (e) => {
    e.preventDefault();
    if (name.trim() === '' || profileType.trim() === '') {
      alert("Fields can't be empty.");
      return;
    }
    onSave({ name, profileType });
    setShowMessage(true);
    setTimeout(() => navigate('/dashboard'), 1500);
  };

  return (
    <div style={styles.container}>
      <h2>Profile Settings</h2>
      <form onSubmit={handleSave} style={styles.form}>
        <div style={styles.inputContainer}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputContainer}>
          <label>Profile Type</label>
          <input
            type="text"
            value={profileType}
            onChange={(e) => setProfileType(e.target.value)}
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.saveButton}>Save</button>
        <button type="button" onClick={() => navigate('/dashboard')} style={styles.backButton}>Back</button>
      </form>
      {showMessage && <p style={styles.confirmationMessage}>Profile updated successfully!</p>}
      {userAnswers && (
        <div style={styles.answersContainer}>
          <h3>Your Initial Questions and Answers</h3>
          <ul>
            {userAnswers.map((qa, index) => (
              <li key={index}>
                <strong>{qa.questionTitle}:</strong> {qa.answer || 'Not answered'}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  form: {
    marginTop: '20px',
  },
  inputContainer: {
    marginBottom: '15px',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  saveButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  confirmationMessage: {
    marginTop: '15px',
    color: '#28a745',
  },
  answersContainer: {
    marginTop: '30px',
    textAlign: 'left',
  },
};

export default ProfileSettings;
