import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import './App.css';
import NavBar from './NavBar';
import Header from './Header';
import RecentActivities from './RecentActivities';
import WearablesSummary from './WearablesSummary';
import GoalTracking from './GoalTracking';
import Dashboard from './Dashboard';
import Login from './Login';
import ProfileSettings from './ProfileSettings';
import Recommendations from './Recommendations';
import GoalSetting from './GoalSetting';
import AnalyticsDashboard from './AnalyticsDashboard';
import Modal from './Modal';
import TypeformQuestions from './TypeformQuestions';
import Upload from './Upload';
// import Calendar from './Calendar';
// import Last4Weeks from './Last4Weeks';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userAnswers, setUserAnswers] = useState(() => {
    return JSON.parse(localStorage.getItem('userAnswers')) || null;
  });

  const [userData, setUserData] = useState({
    name: 'Richard Sanders',
    profilePicture: 'https://lh3.googleusercontent.com/a/ACg8ocKiPs32hPHnXh-nXEgwolBfYLIUYy_t5NCwWTKmx0zC5yFOrpZK=s96-c',
    profileType: 'Health Enthusiast',
    totalDistance: 120,
    totalTime: 56,
    totalCalories: 34000,
  });

  const totalActivities = 5;  // Example data
  const activeDays = [30,1,3,7,15];  // Example active days

  const [goals, setGoals] = useState({
    steps: { goal: 10000, current: 9152 },
    sleep: { goal: 8, current: 6.5 },
    distance: { goal: 50, current: 10 },
    calories: { goal: 2000, current: 400 },
  });

  const stepsData = [
    { day: 'Monday', steps: 8000 },
    { day: 'Tuesday', steps: 10000 },
    { day: 'Wednesday', steps: 7000 },
    { day: 'Thursday', steps: 9500 },
    { day: 'Friday', steps: 12000 },
    { day: 'Saturday', steps: 11000 },
    { day: 'Sunday', steps: 9000 },
  ];

  const caloriesData = [
    { day: 'Monday', burned: 0, goal: 2000 },
    { day: 'Tuesday', burned: 0, goal: 2000 },
    { day: 'Wednesday', burned: 0, goal: 2000 },
    { day: 'Thursday', burned: 0, goal: 2000 },
    { day: 'Friday', burned: 0, goal: 2000 },
    { day: 'Saturday', burned: 0, goal: 2000 },
    { day: 'Sunday', burned: 0, goal: 2000 },
  ];

  const sleepData = [
    { day: 'Monday', hours: 7 },
    { day: 'Tuesday', hours: 6 },
    { day: 'Wednesday', hours: 8 },
    { day: 'Thursday', hours: 5 },
    { day: 'Friday', hours: 7 },
    { day: 'Saturday', hours: 9 },
    { day: 'Sunday', hours: 6 },
  ];

  useEffect(() => {
    if (isLoggedIn && !userAnswers) {
      setShowModal(true);
    }
  }, [isLoggedIn, userAnswers]);

  const handleLogin = (user) => {
    setUserData((prevData) => ({ ...prevData, email: user.email }));
    setTimeout(() => {
      setIsLoggedIn(true);
    }, 100);
  };

  const handleSubmitTypeform = (userAnswersFromModal, questions) => {
    const formattedAnswers = questions.map((question) => ({
      questionTitle: question.title,
      answer: userAnswersFromModal[question.id] || '',
    }));

    setUserAnswers(formattedAnswers);
    localStorage.setItem('userAnswers', JSON.stringify(formattedAnswers));
    setShowModal(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('userAnswers');
  };

  const handleSaveProfile = (updatedUser) => {
    setUserData((prevData) => ({
      ...prevData,
      name: updatedUser.name,
      profileType: updatedUser.profileType,
      profilePicture: updatedUser.profilePicture || prevData.profilePicture,
    }));
  };

  const handleSaveGoals = (updatedGoals) => {
    setGoals(updatedGoals);
  };

  const handleCloseModal = () => {
    setShowModal(false);  // Close the modal
  };

  const [activityData, setActivityData] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      fetch('/data/garmin/af_celsius_2_28_2024.json')
        .then(response => response.json())
        .then(data => setActivityData(data))
        .catch(error => console.error('Error loading JSON:', error));
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <NavBar user={userData} onLogout={handleLogout} />}
        <Routes>
          {!isLoggedIn ? (
            <Route path="/" element={<Login onLogin={handleLogin} />} />
          ) : (
            <>
              <Route
                path="/dashboard"
                element={
                  <>
                    <Header user={userData} totalActivities={totalActivities} activeDays={activeDays} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <RecentActivities />
                      <GoalTracking goals={goals} />
                      <WearablesSummary activityData={activityData} />
                    </div>
                    {/* <GoalSetting goals={goals} />
                    <AnalyticsDashboard stepsData={stepsData} caloriesData={caloriesData} sleepData={sleepData} />
                    <Recommendations user={userData} goals={goals} wearableData={caloriesData} /> */}
                  </>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProfileSettings
                    user={userData}
                    userAnswers={userAnswers}
                    onSave={handleSaveProfile}
                  />
                }
              />
              <Route path="/upload/*" element={<Upload />} /> {/* Add the route for the upload page */}
            </>
          )}
        </Routes>
        {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <TypeformQuestions onSubmit={handleSubmitTypeform} />
          </Modal>
        )}
      </div>
    </Router>
  );
}

export default App;
