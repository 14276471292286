import React from 'react';

const File = () => {
  return (
    <div className="file-section">
      <h2>Upload a File</h2>
      <form>
        <input type="file" />
        <button type="submit">Upload</button>
      </form>
      <p>
        Works for multiple .tcx, .fit or .gpx files 25MB or smaller. Choose up to 15 files.
      </p>
    </div>
  );
};

export default File;
