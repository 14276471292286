import React from 'react';

const Device = () => {
  return (
    <div className="device-section">
      <h2>Upload from Device</h2>
      <div className="device-options">
        {/* Example for one device, repeat for others */}
        <div className="device-option">
          <img src="https://d3nn82uaxijpm6.cloudfront.net/assets/application/uploads/garmin-logo-bf4971f0d0e821f6880db85c3a95a3ce2edc3881c88eea691a883020b8d26b9e.png" alt="Garmin" />
          <br />
          <br />
          <button>Get Started</button>
        </div>
        {/* Repeat for other devices */}
      </div>
    </div>
  );
};

export default Device;
